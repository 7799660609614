/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { Box, Checkbox, FormHelperText, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../components/Button';
import AppBarWithoutMenu from '../../components/AppBar/AppBarWithoutMenu';
import { AppBarBody } from '../../components/AppBar/AppBarBody';
import CustomizedDialogs from '../../components/CustomizedDialogs';
import AlertMessage from '../../components/Alert';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
    getRegisterParameters,
    getParameterResults,
} from '../../store/slices/register/parameterSlice';
import {
    commonStatusTypes,
    ERROR_CODE,
    localStorageTypes,
    MALAYSIA_PHONE_CODES,
    MASTER_CODE,
    MASTER_MEMBER_CODE,
} from '../../services/constant';
import './RegisterPage.css';
import {
    getRegisterUserResults,
    registerNewUser,
} from '../../store/slices/register/registerSlice';
import Spinner from '../../components/Spinner';
import { URLS } from '../../services/urls';
import { FormValuesTypes } from '../../services/types';
import { IRegister, ConsentList } from '../../services/api/initialStates';
import { ReqRegister } from '../../services/api/requestBody';
import {
    AuthTokens,
    FormDataProps,
    ParametersProps,
} from '../../services/interfaces';
import RegisterForm from '../../components/RegisterForm';
import { useTranslation } from 'react-i18next';

function filterParameter(
    parameters: IRegister['parameter'][],
    masterMemberCode: string,
) {
    return parameters.filter(
        (param: { masterMemberCode: string }) =>
            param.masterMemberCode === masterMemberCode,
    )[0];
}

function RegisterPage() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [formData] = useState<FormDataProps>(
        JSON.parse(localStorage.getItem('user') || '{}'),
    );
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [companyTelNo, setCompanyTelNo] = useState('');
    const [selectedPreferredLanguageCode, setSelectedPreferredLanguageCode] =
        useState('');
    const [countries, setCountries] = useState<ParametersProps[]>(
        [] as ParametersProps[],
    );
    const [languages, setLanguages] = useState<ParametersProps[]>(
        [] as ParametersProps[],
    );
    const [errorCode, setErrorCode] = useState('');
    const { t } = useTranslation();

    // redux
    const dispatch = useAppDispatch();
    const parameters = useAppSelector(getParameterResults);
    const submitResult = useAppSelector(getRegisterUserResults);

    const authTokens: AuthTokens = JSON.parse(
        localStorage.getItem(localStorageTypes.AUTH_TOKENS) || '{}',
    );

    const {
        handleSubmit,
        getValues,
        register,
        setValue,
        formState: { errors, isValid },
    } = useForm<FormValuesTypes>({ mode: 'onChange' });

    useEffect(() => {
        if (authTokens.refreshToken) {
            navigate(URLS.MEMBER.HOME.MAIN);
        }

        dispatch(getRegisterParameters()).then(res => {
            if (res.meta.requestStatus === 'fulfilled') {
                const countryList: ParametersProps[] =
                    res.payload.parameters.filter(
                        (param: { masterCode: string; status: string }) =>
                            param.masterCode === MASTER_CODE.COUNTRY &&
                            param.status === MASTER_MEMBER_CODE.ACTIVE,
                    );

                setCountries(countryList);

                const preferredLanguage: ParametersProps[] =
                    res.payload.parameters.filter(
                        (param: { masterCode: string; status: string }) =>
                            param.masterCode === MASTER_CODE.LANGUAGE_TYPE &&
                            param.status === MASTER_MEMBER_CODE.ACTIVE,
                    );

                setLanguages(preferredLanguage);

                if (formData.country) {
                    setValue('country', formData.country);
                    setSelectedCountryCode(formData.country);
                } else {
                    setValue('country', MASTER_MEMBER_CODE.MYS);
                    setSelectedCountryCode(MASTER_MEMBER_CODE.MYS);
                }

                if (formData.phoneNumber) {
                    setValue('phoneNumber', formData.phoneNumber);
                    setPhoneNumber(formData.phoneNumber);
                } else {
                    setPhoneNumber(MALAYSIA_PHONE_CODES);
                }

                if (formData.preferredLanguage) {
                    setValue(
                        'preferredLanguageCode',
                        formData.preferredLanguage,
                    );
                    setSelectedPreferredLanguageCode(
                        formData.preferredLanguage,
                    );
                } else {
                    setValue('preferredLanguageCode', MASTER_MEMBER_CODE.EN);
                    setSelectedPreferredLanguageCode(MASTER_MEMBER_CODE.EN);
                }
            }
        });
    }, [
        authTokens.refreshToken,
        dispatch,
        formData.country,
        formData.phoneNumber,
        formData.preferredLanguage,
        navigate,
        setValue,
    ]);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const onSubmit = (data: FormValuesTypes) => {
        const isFormErrors = JSON.stringify(errors) === '{}';
        if (!isFormErrors) {
            setOpenDialog(true);
            return;
        }

        setBackdropOpen(true);
        setLoading(true);

        const consentsList: ConsentList[] = [];

        /* Add Terms and Condition */
        if (data.terms) {
            const filters = filterParameter(
                parameters.list,
                MASTER_MEMBER_CODE.USER_TERMS_CONDITION,
            );

            const consent = {
                consentCode: filters.masterMemberCode,
                consentDescription: filters.masterMemberDesc,
                consentChecked: data.terms,
            };

            consentsList.push(consent);
        }

        /* Add PDPA Policy */
        if (data.pdpa) {
            const filters = filterParameter(
                parameters.list,
                MASTER_MEMBER_CODE.USER_PDPA,
            );
            const consent = {
                consentCode: filters.masterMemberCode,
                consentDescription: filters.masterMemberDesc,
                consentChecked: data.pdpa,
            };

            consentsList.push(consent);
        }

        /* Add Marketing Materials */
        const filters = filterParameter(
            parameters.list,
            MASTER_MEMBER_CODE.USER_MARKETING,
        );

        const consent = {
            consentCode: filters.masterMemberCode,
            consentDescription: filters.masterMemberDesc,
            consentChecked: data.marketingMaterials,
        };

        consentsList.push(consent);

        const countryDesc = filterParameter(
            parameters.list,
            selectedCountryCode,
        );

        const reqData: ReqRegister['registration'] = {
            fullName: data.name,
            email: data.email,
            phoneNumber,
            countryCode: selectedCountryCode,
            countryName: countryDesc.masterMemberDesc,
            consents: consentsList,
            languageCode: selectedPreferredLanguageCode,
            companyName: data.companyName,
            companyAddress: data.companyAddress,
            companyTelNo: data.companyTelNo,
        };

        dispatch(registerNewUser(reqData)).then(res => {
            setLoading(false);
            if (res.meta.requestStatus === 'fulfilled') {
                localStorage.clear();
                navigate(URLS.PUBLIC.REGISTER.SUCCESS, {
                    state: {
                        access: true,
                    },
                });
            }

            if (
                res.meta.requestStatus === 'rejected' &&
                res.payload.status !== 401
            ) {
                setErrorCode(res.payload.code);
            }
        });
    };

    const onLocalStorageUpdate = () => {
        const data = {
            name: getValues('name'),
            email: getValues('email'),
            phoneNumber,
            country: selectedCountryCode,
            terms: getValues('terms'),
            pdpa: getValues('pdpa'),
            marketingMaterials: getValues('marketingMaterials'),
        };
        const json = JSON.stringify(data);
        localStorage.setItem('user', json);
    };

    const onClickSubmit = () => {
        if (!isValid) {
            setOpenDialog(true);
        }
    };

    return (
        <>
            {!authTokens.refreshToken && (
                <>
                    {loading ? <Spinner open={backdropOpen} /> : <div />}

                    {openDialog ? (
                        <CustomizedDialogs
                            open={openDialog}
                            title={t('text.alert')}
                            body={t('error.all_fields_required')}
                            primaryButton={t('button.close')}
                            onPrimaryButtonClick={handleCloseDialog}
                            isContainDialogAction
                            isContainTwoButtons={false}
                        />
                    ) : (
                        <div />
                    )}

                    <AppBarWithoutMenu
                        title={t('button.register_now')}
                        link="/"
                    />
                    <AppBarBody />

                    <Box sx={{ p: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {submitResult.status ===
                                    commonStatusTypes.FAILED &&
                                errorCode !== ERROR_CODE.EMAIL_ALREADY_EXIST ? (
                                    <AlertMessage
                                        showAlert
                                        errorMessage={submitResult.errorMsg}
                                    />
                                ) : (
                                    <div />
                                )}

                                {submitResult.status ===
                                    commonStatusTypes.FAILED &&
                                    errorCode ===
                                        ERROR_CODE.EMAIL_ALREADY_EXIST && (
                                        <AlertMessage showAlert>
                                            <p>
                                                {submitResult.errorMsg}{' '}
                                                <a
                                                    href={
                                                        URLS.PUBLIC.LOGIN.MAIN
                                                    }
                                                >
                                                    [{t('text.login_instead')}
                                                    ?]
                                                </a>
                                            </p>
                                        </AlertMessage>
                                    )}

                                <div
                                    className="montserrat-medium-black-22px"
                                    style={{
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                    }}
                                >
                                    {t('text.let_get_started')}
                                </div>
                                <div className="montserrat-medium-black-17px">
                                    {t('error.fill_up_the_form_to_continue')}
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={3}>
                                        <RegisterForm
                                            register={register}
                                            errors={errors}
                                            formData={formData}
                                            dropdownList={countries}
                                            languagesdropdown={languages}
                                            getValues={getValues}
                                            setValue={setValue}
                                            // to set
                                            selectedCountryCode={
                                                selectedCountryCode
                                            }
                                            setSelectedCountryCode={
                                                setSelectedCountryCode
                                            }
                                            phoneNumber={phoneNumber}
                                            setPhoneNumber={setPhoneNumber}
                                            companyTelNo={companyTelNo}
                                            setCompanyTelNo={setCompanyTelNo}
                                            selectedPreferredLanguageCode={
                                                selectedPreferredLanguageCode
                                            }
                                            setSelectedPreferredLanguageCode={
                                                setSelectedPreferredLanguageCode
                                            }
                                        />
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                rowSpacing={1}
                                                columnSpacing={{
                                                    xs: 1,
                                                    sm: 2,
                                                    md: 3,
                                                }}
                                            >
                                                <Grid item xs={1}>
                                                    <Checkbox
                                                        {...register('terms', {
                                                            required: t(
                                                                'error.this_is_required',
                                                            ),
                                                        })}
                                                        style={
                                                            errors.terms
                                                                ?.message
                                                                ? {
                                                                      color: '#d32f2f',
                                                                      padding:
                                                                          '0px',
                                                                  }
                                                                : {
                                                                      padding:
                                                                          '0px',
                                                                  }
                                                        }
                                                        defaultChecked={
                                                            !!(
                                                                formData &&
                                                                formData.terms
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <div
                                                        className="montserrat-medium-tuatara-12px"
                                                        style={{
                                                            paddingTop: '5px',
                                                            lineHeight: '15px',
                                                            textDecoration:
                                                                'underline',
                                                            textDecorationColor:
                                                                '#1976d2',
                                                        }}
                                                    >
                                                        {parameters.list
                                                            .filter(
                                                                (param: {
                                                                    masterMemberCode: string;
                                                                }) =>
                                                                    param.masterMemberCode ===
                                                                    MASTER_MEMBER_CODE.USER_TERMS_CONDITION,
                                                            )
                                                            .map(
                                                                (text: {
                                                                    masterMemberDesc: string;
                                                                }) => (
                                                                    <a
                                                                        key="userTerms"
                                                                        href={t(
                                                                            'url.terms',
                                                                        )}
                                                                        target="_blank"
                                                                        onClick={
                                                                            onLocalStorageUpdate
                                                                        }
                                                                        style={{
                                                                            color: errors
                                                                                .terms
                                                                                ?.message
                                                                                ? '#d32f2f'
                                                                                : '#1976d2',
                                                                        }}
                                                                        rel="noreferrer"
                                                                    >
                                                                        {
                                                                            text.masterMemberDesc
                                                                        }
                                                                    </a>
                                                                ),
                                                            )}
                                                    </div>
                                                    {errors.terms?.message && (
                                                        <FormHelperText
                                                            sx={{
                                                                color: '#d32f2f',
                                                                paddingTop:
                                                                    '5px',
                                                            }}
                                                        >
                                                            {errors.terms.message.toString()}
                                                        </FormHelperText>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                rowSpacing={1}
                                                columnSpacing={{
                                                    xs: 1,
                                                    sm: 2,
                                                    md: 3,
                                                }}
                                            >
                                                <Grid item xs={1}>
                                                    <Checkbox
                                                        {...register('pdpa', {
                                                            required: t(
                                                                'error.this_is_required',
                                                            ),
                                                        })}
                                                        style={
                                                            errors.pdpa?.message
                                                                ? {
                                                                      color: '#d32f2f',
                                                                      padding:
                                                                          '0px',
                                                                  }
                                                                : {
                                                                      padding:
                                                                          '0px',
                                                                  }
                                                        }
                                                        defaultChecked={
                                                            !!(
                                                                formData &&
                                                                formData.pdpa
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <div
                                                        className="montserrat-medium-tuatara-12px"
                                                        style={{
                                                            lineHeight: '15px',
                                                            textDecoration:
                                                                'underline',
                                                            textDecorationColor:
                                                                '#1976d2',
                                                        }}
                                                    >
                                                        {parameters.list
                                                            .filter(
                                                                (param: {
                                                                    masterMemberCode: string;
                                                                }) =>
                                                                    param.masterMemberCode ===
                                                                    MASTER_MEMBER_CODE.USER_PDPA,
                                                            )
                                                            .map(
                                                                (param: {
                                                                    masterMemberDesc: string;
                                                                }) => (
                                                                    <a
                                                                        key="pdpa"
                                                                        href={t(
                                                                            'url.privacyPolicy',
                                                                        )}
                                                                        target="_blank"
                                                                        onClick={
                                                                            onLocalStorageUpdate
                                                                        }
                                                                        style={{
                                                                            color: errors
                                                                                .pdpa
                                                                                ?.message
                                                                                ? '#d32f2f'
                                                                                : '#1976d2',
                                                                        }}
                                                                        rel="noreferrer"
                                                                    >
                                                                        {
                                                                            param.masterMemberDesc
                                                                        }
                                                                    </a>
                                                                ),
                                                            )}
                                                    </div>
                                                    {errors.pdpa?.message && (
                                                        <FormHelperText
                                                            sx={{
                                                                color: '#d32f2f',
                                                                paddingTop:
                                                                    '5px',
                                                            }}
                                                        >
                                                            {errors.pdpa.message.toString()}
                                                        </FormHelperText>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                rowSpacing={1}
                                                columnSpacing={{
                                                    xs: 1,
                                                    sm: 2,
                                                    md: 3,
                                                }}
                                            >
                                                <Grid item xs={1}>
                                                    <Checkbox
                                                        {...register(
                                                            'marketingMaterials',
                                                        )}
                                                        style={{
                                                            padding: '0px',
                                                        }}
                                                        defaultChecked={
                                                            !!(
                                                                formData &&
                                                                formData.marketingMaterials
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <div
                                                        className="montserrat-medium-tuatara-12px"
                                                        style={{
                                                            paddingTop: '5px',
                                                            lineHeight: '15px',
                                                        }}
                                                    >
                                                        {parameters.list
                                                            .filter(
                                                                (param: {
                                                                    masterMemberCode: string;
                                                                }) =>
                                                                    param.masterMemberCode ===
                                                                    MASTER_MEMBER_CODE.USER_MARKETING,
                                                            )
                                                            .map(
                                                                (text: {
                                                                    masterMemberDesc: string;
                                                                }) => (
                                                                    <p key="userMarketing">
                                                                        {
                                                                            text.masterMemberDesc
                                                                        }
                                                                    </p>
                                                                ),
                                                            )}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PrimaryButton
                                                isFullWidth
                                                buttonName={t(
                                                    'button.register_now',
                                                )}
                                                callback={onClickSubmit}
                                                type="submit"
                                                disabled={false}
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
            <div />
        </>
    );
}

export default RegisterPage;
