import moment from 'moment';
/* Pagination */
export const commonPagination = {
    page: 0,
    size: 20,
    sort: '',
};

/* Common Usage Value */
export const commonStatusTypes = {
    IDLE: 'idle',
    LOADING: 'loading',
    SUCCESS: 'success',
    FAILED: 'failed',
};

/* local storage value */
export const localStorageTypes = {
    CODE: 'code',
    EMAIL: 'email',
    AUTH_TOKENS: 'authTokens',
    USER_AUTH: 'userAuth',
    PREV_URL: 'prevUrl',
    FULFILMENT_IMAGE: 'fulfilmentImage',
    FULFILMENT_ID_TYPE: 'fulfilmentIdType',
    FULFILMENT_REQUEST_ID: 'fulfilmentRequestId',
    IS_FROM_REQUEST: 'isFromRequest',
    NON_MEMBER: 'nonMember',
    REQUEST_CHECK_ITEMS: 'requestCheckItems',
    REQUEST_INPUT_DETAILS: 'requestInputDetails',
    REQUEST_TNC_PDPA: 'requestTnCPdpa',
    FIRST_TIME_LOGIN: 'firstTimeLogin',
    VERIFICATION_REQUEST_CHECK_ITEMS: 'verificationRequestCheckItems',
    SELF_VERIFICATION: 'selfVerification',
    IS_SELF_VERIFIED: 'isSelfVerified',
    SELF_VERIFICATION_STATUS: 'selfVerificationStatus',
    IS_USE_SELF_VERIFICATION: 'isUseSelfVerification',
};

/* common fields id */
export const commonFieldsId = {
    NAME: 'name',
    EMAIL: 'email',
    PHONE_NUMBER: 'phoneNumber',
    COUNTRY: 'country',
    PDPA: 'pdpa',
    TERMS: 'terms',
    MARKETING_MATERIALS: 'marketingMaterials',
    ID_TYPE: 'idType',
    NUMBER_OF_CREDITS: 'numberOfCredits',
    PAYMENT_METHOD: 'paymentMethod',
};

export const MASTER_MEMBER_DESCRIPTION = {
    REQUEST_ACCEPTED: 'REQUEST_ACCEPTED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    NOT_STARTED: 'NOT_STARTED',
    CANCELLED: 'CANCELLED',
};

export const MASTER_CODE = {
    COUNTRY: 'COUNTRY',
    VAAZME_CHECK_ITEMS: 'VAAZME_CHECK_ITEMS',
    PAYMENT_METHOD: 'PAYMENT_METHOD',
    LANGUAGE_TYPE: 'LANGUAGE_TYPE',
};

export const MASTER_MEMBER_CODE = {
    ACTIVE: 'ACTIVE',
    FULFIL_MARKETING: 'FULFIL_MARKETING',
    FULFIL_PDPA: 'FULFIL_PDPA',
    FULFIL_TERMS_CONDITIONS: 'FULFIL_TERMS_CONDITIONS',
    FULFIL_IDV: 'FULFIL_IDV',
    FULFIL_DATA_OBFUSCATION: 'FULFIL_DATA_OBFUSCATION',
    USER_TERMS_CONDITION: 'USER_TERMS_CONDITIONS',
    USER_PDPA: 'USER_PDPA',
    USER_MARKETING: 'USER_MARKETING',
    REQUEST_SELF_VERIFICATION: 'REQUEST_SELF_VERIFICATION',
    REQUEST_CONSENT: 'REQUEST_CONSENT',
    REQUEST_PDPA: 'REQUEST_PDPA',
    REQUEST_TERMS_CONDITIONS: 'REQUEST_TERMS_CONDITIONS',
    REQUEST_ACCEPTED: 'REQUEST_ACCEPTED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    NOT_STARTED: 'NOT_STARTED',
    CANCELLED: 'CANCELLED',
    MSG_REQUEST: 'MSG_REQUEST',
    MSG_FULFILMENT: 'MSG_FULFILMENT',
    UNREAD: 'UNREAD',
    READ: 'READ',
    USER_PENDING_VERIFICATION: 'USER_PENDING_VERIFICATION',
    ID_MALAYSIA: 'ID_MALAYSIA',
    ID_PASSPORT: 'ID_PASSPORT',
    VC_SUCCESS: 'VC_SUCCESS',
    DEDUCT: 'DEDUCT',
    VISA: 'VISA',
    GRAB_PAY: 'GRAB_PAY',
    TNG: 'TNG',
    BOOST: 'BOOST',
    CIMB_PAY: 'CIMB_PAY',
    MAE: 'MAE',
    MYS: 'MYS',
    KHM: 'KHM',
    SGP: 'SGP',
    EN: 'EN',
    MS: 'MS',
    ZH: 'ZH',
    SHARE_RESULTS: 'SHARE_RESULTS',
    ADMIN_DEDUCT: 'ADMIN_DEDUCT',
};

export const ERROR_CODE = {
    ACCOUNT_NOT_VERIFIED: 'otp.error.2',
    LOGIN_VERIFICATION_LINK_EXPIRED: 'otp.error.3',
    EMAIL_NOT_FOUND: 'registration.email.err.2',
    REGISTER_VERIFICATION_LINK_EXPIRED:
        'registration.verification.expired.err.1',
    EMAIL_ALREADY_EXIST: 'registration.email.err.3',
    AVAILABLE: 'AVAILABLE',
    UNAVAILABLE: 'UNAVAILABLE',
};

export const CHECK_ITEMS_TOOLTIPS = {
    IDV: 'Use this to verify that the Subject has legitimate identity documents (e.g. NRIC, Passport).',
};

export const MOBILE_OS = [
    {
        Name: 'Chrome',
        AnKey: 'Chrome',
        AKey: 'CriOS',
        Expected: 55,
    },
    {
        Name: 'Firefox',
        AnKey: 'Firefox',
        AKey: 'FxiOS',
        Expected: 15,
    },
    {
        Name: 'Opera',
        AnKey: 'Version',
        AKey: 'Version',
        Expected: 42,
    },
    {
        Name: 'Safari',
        AnKey: 'Version',
        AKey: 'Version',
        Expected: 11,
    },
    {
        Name: 'Samsung',
        AnKey: '',
        AKey: '',
        Expected: 6.0,
    },
    {
        Name: 'WebView',
        AnKey: '',
        AKey: '',
        Expected: 55,
    },
];
export const WEB_OS = [
    {
        Name: 'Chrome',
        Key: 'Chrome',
        Expected: 55,
    },
    {
        Name: 'Edge',
        Key: 'Edg',
        Expected: 12,
    },
    {
        Name: 'Firefox',
        Key: 'Firefox',
        Expected: 15,
    },
    {
        Name: 'Safari',
        Key: 'Safari',
        Expected: 11,
    },
    {
        Name: 'Opera',
        Key: 'OPR',
        Expected: 42,
    },
];

export const BASE64_COMMON_STRING_PNG = 'data:image/png;base64,';
export const BASE64_COMMON_STRING = 'data:image/jpg;base64,';
export const MALAYSIA_PHONE_CODES = '(+60) ';
export const TIMER_MILLISECONDS = 10000;

export function customFormatDate(
    dateTime: any,
    formatDate: any,
    isMalay: any,
    upperCase: boolean,
) {
    const formatString = formatDate;
    const formatted = moment(dateTime)
        .locale(isMalay ? 'ms' : 'en')
        .format(formatString);

    if (isMalay) {
        return formatted
            .replace('pagi', upperCase ? 'AM' : 'am')
            .replace('petang', upperCase ? 'PM' : 'pm')
            .replace('tengahari', upperCase ? 'PM' : 'pm')
            .replace('malam', upperCase ? 'PM' : 'pm');
    }

    return formatted;
}
