/* eslint-disable react/jsx-props-no-spreading */
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import React from 'react';
import { FormProps } from '../../services/interfaces';
import {
    checkPhoneNumberLength,
    phoneNumberInternationalization,
} from '../../services/utils';
import { useTranslation } from 'react-i18next';

export default function RegisterForm(props: FormProps) {
    const {
        register,
        errors,
        formData,
        dropdownList,
        languagesdropdown,

        selectedCountryCode,
        setSelectedCountryCode,
        phoneNumber,
        setPhoneNumber,
        companyTelNo,
        setCompanyTelNo,
        selectedPreferredLanguageCode,
        setSelectedPreferredLanguageCode,
    } = props;

    const { t } = useTranslation();

    const onCountryChange = (event: SelectChangeEvent) => {
        const country = event.target.value as string;
        setSelectedCountryCode(country);
        setPhoneNumber(phoneNumberInternationalization(country, ''));
    };

    const onPhoneNumberChange = (event: React.BaseSyntheticEvent) => {
        const pNumber = event.target.value;
        setPhoneNumber(
            phoneNumberInternationalization(selectedCountryCode, pNumber),
        );
    };

    const onCompanyTelNoChange = (event: React.BaseSyntheticEvent) => {
        const pNumber = event.target.value;
        setCompanyTelNo?.(
            phoneNumberInternationalization(selectedCountryCode, pNumber),
        );
    };

    const checkLength = (value: string) => {
        return checkPhoneNumberLength(
            phoneNumberInternationalization(selectedCountryCode, value),
        );
    };

    const onPreferredLanguageChange = (event: SelectChangeEvent) => {
        const pLanguage = event.target.value as string;
        setSelectedPreferredLanguageCode(pLanguage);
    };

    return (
        <>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    {...register('name', {
                        required: t('error.this_is_required'),
                    })}
                    defaultValue={formData?.name || ''}
                    label={t('label.name')}
                    className="montserrat-medium-outer-space-16px"
                    error={!!errors.name?.message}
                    helperText={errors ? errors.name?.message : null}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    {...register('email', {
                        required: t('error.this_is_required'),
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: t('error.invalid_email'),
                        },
                    })}
                    type="email"
                    defaultValue={formData?.email || ''}
                    label={t('label.email')}
                    className="montserrat-medium-outer-space-16px"
                    error={!!errors.email?.message}
                    helperText={errors ? errors.email?.message : null}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth error={!!errors.country?.message}>
                    <InputLabel id="demo-simple-select-label">
                        {t('label.country')}
                    </InputLabel>
                    <Select
                        {...register('country', {
                            value: selectedCountryCode,
                            required: t('error.this_is_required'),
                        })}
                        value={selectedCountryCode}
                        label={t('label.country')}
                        onChange={onCountryChange}
                    >
                        {dropdownList?.map(
                            (item: {
                                masterMemberCode: string;
                                masterMemberDesc: string;
                            }) => (
                                <MenuItem
                                    key={item.masterMemberCode}
                                    value={item.masterMemberCode}
                                >
                                    {item.masterMemberDesc}
                                </MenuItem>
                            ),
                        )}
                    </Select>
                    <FormHelperText>
                        {errors ? errors.country?.message : null}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                {/* <TextField
                    {...register('phoneNumber', {
                        required: t(error_this_is_required,
                        validate: checkLength,
                    })}
                    fullWidth
                    label={t(label_phone_number}
                    value={phoneNumber || ''}
                    className="montserrat-medium-outer-space-16px"
                    error={!!errors.phoneNumber?.message}
                    helperText={errors ? errors.phoneNumber?.message : null}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type="tel"
                    onChange={onPhoneNumberChange}
                /> */}
                <TextField
                    fullWidth
                    {...register('phoneNumber', {
                        required: t('error.this_is_required'),
                        validate: {
                            toCheckLength: (value: string) =>
                                checkLength(value),
                            toCheckNumberOnly: (value: string) => {
                                if (!/^([^A-Za-z]*)$/.test(value)) {
                                    return t('error.phone_number_is_not_valid');
                                }

                                const specialChars =
                                    /[`!@#$%^&*_\-=[\]{};':"\\|,.<>/?~]/;
                                if (specialChars.test(value)) {
                                    return t('error.phone_number_is_not_valid');
                                }

                                return true;
                            },
                        },
                    })}
                    value={phoneNumber || ''}
                    label={t('label.phone_number')}
                    className="montserrat-medium-outer-space-16px"
                    error={!!errors.phoneNumber?.message}
                    helperText={errors ? errors.phoneNumber?.message : null}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type="tel"
                    onChangeCapture={onPhoneNumberChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    {...register('companyName')}
                    defaultValue={formData?.companyName || ''}
                    label={t('label.companyName')}
                    className="montserrat-medium-outer-space-16px"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    {...register('companyAddress')}
                    defaultValue={formData?.companyAddress || ''}
                    label={t('label.companyAddress')}
                    className="montserrat-medium-outer-space-16px"
                    multiline
                    rows={4}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    {...register('companyTelNo', {
                        required: false,
                        validate: {
                            toCheckLength: (value: string) => {
                                if (
                                    value !== undefined &&
                                    value !== null &&
                                    value.length > 0
                                ) {
                                    return checkLength(value);
                                }

                                return true;
                            },
                            toCheckNumberOnly: (value: string) => {
                                if (
                                    value !== undefined &&
                                    value !== null &&
                                    value.length > 0
                                ) {
                                    if (!/^([^A-Za-z]*)$/.test(value)) {
                                        return t('error.invalid_tel_no');
                                    }

                                    const specialChars =
                                        /[`!@#$%^&*_\-=[\]{};':"\\|,.<>/?~]/;
                                    if (specialChars.test(value)) {
                                        return t('error.invalid_tel_no');
                                    }
                                }
                                return true;
                            },
                        },
                    })}
                    value={companyTelNo || ''}
                    label={t('label.companyTelNo')}
                    error={!!errors.companyTelNo?.message}
                    helperText={errors ? errors.companyTelNo?.message : null}
                    className="montserrat-medium-outer-space-16px"
                    type="tel"
                    onChangeCapture={onCompanyTelNoChange}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl
                    fullWidth
                    error={!!errors.preferredLanguageCode?.message}
                >
                    <InputLabel id="demo-simple-select-label">
                        {t('label.preferred_language')}
                    </InputLabel>
                    <Select
                        {...register('preferredLanguageCode', {
                            value: selectedPreferredLanguageCode,
                            required: t('error.this_is_required'),
                        })}
                        value={selectedPreferredLanguageCode}
                        label={t('label.preferred_language')}
                        onChange={onPreferredLanguageChange}
                    >
                        {languagesdropdown?.map(
                            (item: {
                                masterMemberCode: string;
                                masterMemberDesc: string;
                            }) => (
                                <MenuItem
                                    key={item.masterMemberCode}
                                    value={item.masterMemberCode}
                                >
                                    {item.masterMemberDesc}
                                </MenuItem>
                            ),
                        )}
                    </Select>
                    <FormHelperText>
                        {errors ? errors.preferredLanguageCode?.message : null}
                    </FormHelperText>
                </FormControl>
            </Grid>
        </>
    );
}
