import moment from 'moment';
import {
    localStorageTypes,
    MASTER_MEMBER_CODE,
    MASTER_MEMBER_DESCRIPTION,
} from './constant';
import { CheckItemsWithDescriptionProps } from './interfaces';
import { URLS } from './urls';
import { i18n } from '../i18n/i18n';

export function phoneNumberInternationalization(
    countryCode: string,
    phoneNumber: string,
) {
    const exp = phoneNumber.match(/^\(?\+?(60|65|855)?\)?(\d{8,10})$/);
    if (exp) {
        let intlCountryCode = '+60';
        if (countryCode === MASTER_MEMBER_CODE.SGP) {
            intlCountryCode = '+65';
        } else if (countryCode === MASTER_MEMBER_CODE.KHM) {
            intlCountryCode = '+855';
        }
        const interNumber = `(${intlCountryCode}) ${exp[1] ?? ''}${exp[2]}`;

        return interNumber;
    }
    return phoneNumber;
}

export function checkPhoneNumberLength(phoneNumber: string) {
    const mobile = phoneNumber.replace(/([[(])(.+?)([\])]) /g, '');
    const translate = i18n;

    if (mobile.length < 8) {
        return translate.t('error.minimum_8_digit');
    }
    if (mobile.length > 10) {
        return translate.t('error.maximum_10_digit');
    }

    return true;
}

export function getStatusDescription(masterMemberCode: string) {
    if (masterMemberCode === MASTER_MEMBER_CODE.IN_PROGRESS) {
        return MASTER_MEMBER_DESCRIPTION.IN_PROGRESS;
    }
    if (masterMemberCode === MASTER_MEMBER_CODE.COMPLETED) {
        return MASTER_MEMBER_DESCRIPTION.COMPLETED;
    }
    if (masterMemberCode === MASTER_MEMBER_CODE.NOT_STARTED) {
        return MASTER_MEMBER_DESCRIPTION.NOT_STARTED;
    }
    return MASTER_MEMBER_DESCRIPTION.REQUEST_ACCEPTED;
}

export function redirectBasedOnMessageTypeCode(data: {
    messageTypeCode: string;
    status: string;
    requestId: string;
    subjectSvReportConsentFlag: boolean;
}): string {
    const { messageTypeCode, status, requestId, subjectSvReportConsentFlag } =
        data;

    localStorage.setItem(localStorageTypes.FULFILMENT_REQUEST_ID, requestId);
    const selfVerificationStatus = localStorage.getItem(
        localStorageTypes.SELF_VERIFICATION_STATUS,
    );
    if (messageTypeCode === MASTER_MEMBER_CODE.MSG_FULFILMENT) {
        if (
            subjectSvReportConsentFlag &&
            selfVerificationStatus === 'VERIFIED' &&
            !(status === MASTER_MEMBER_DESCRIPTION.COMPLETED)
        ) {
            return URLS.MEMBER.REQUEST.FULFILMENT_OPTION;
        }
        if (status === MASTER_MEMBER_DESCRIPTION.NOT_STARTED) {
            return URLS.MEMBER.REQUEST.VERIFY;
        }

        if (status === MASTER_MEMBER_DESCRIPTION.REQUEST_ACCEPTED) {
            return URLS.MEMBER.REQUEST.ACCEPTED;
        }

        if (status === MASTER_MEMBER_DESCRIPTION.IN_PROGRESS) {
            localStorage.setItem(
                localStorageTypes.FULFILMENT_REQUEST_ID,
                requestId,
            );
            return URLS.MEMBER.FULFILMENT.ID_TYPE;
        }

        if (status === MASTER_MEMBER_DESCRIPTION.COMPLETED) {
            return `${URLS.MEMBER.FULFILMENT.STATIC}complete`;
        }
    }

    return '';
}

export function getAuthTokenFromStorage() {
    return JSON.parse(
        localStorage.getItem(localStorageTypes.AUTH_TOKENS) || '{}',
    );
}

export function calculateCreditAmount(numberOfCredits: number) {
    if (numberOfCredits <= 0) {
        return 0;
    }
    return numberOfCredits / 10;
}

/* Image resize function */
function calcImageSize(image: string) {
    let y = 1;
    if (image.endsWith('==')) {
        y = 2;
    }
    const xSize = image.length * (3 / 4) - y;

    return Math.round(xSize / 1024);
}

async function reduceImageFileSize(
    base64Str: string,
    MAX_WIDTH = 450,
    MAX_HEIGHT = 450,
) {
    const resizedBase64: string = await new Promise(resolve => {
        const img = new Image();
        img.src = base64Str;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            let { width } = img;
            let { height } = img;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx?.drawImage(img, 0, 0, width, height);
            resolve(canvas.toDataURL('image/jpeg')); // this will return base64 image results after resize
        };
    });
    return resizedBase64;
}

export async function processImage(file: string, minImageSize = 3000) {
    if (file) {
        const oldSize = calcImageSize(file);
        if (oldSize > minImageSize) {
            const resized = await reduceImageFileSize(file);
            // const newSize = calcImageSize(resized);
            // console.log('new_size=> ', new_size, 'KB');
            // console.log('old_size=> ', old_size, 'KB');
            return resized;
        }
        // console.log('image already small enough')
        return file;
    }
    // console.log('return err')
    return '';
}

// Checking if the array lengths are same
// and none of the array is empty
export function convertToObj(a: string[], b: string[]) {
    if (a.length !== b.length || a.length === 0 || b.length === 0) {
        return null;
    }

    // Using Object.assign method
    const newObject: CheckItemsWithDescriptionProps[] = Object.assign(
        a.map((k: string, i: number) => ({ item: k, description: b[i] })),
    );

    return newObject;
}

export function checkDateIsValid(date: string) {
    if (date?.includes('*')) {
        // masking value
        return date;
    }

    const isDateValid = moment(date).isValid();
    if (isDateValid) {
        const formatDate = moment(date).locale('en').format('D MMMM YYYY');
        return formatDate;
    }

    return date;
}

export function saveAsPDF(resp: any) {
    const file = new Blob([resp.data], { type: 'application/pdf' });
    const url = URL.createObjectURL(file);
    const contentDisposition = resp.headers['content-disposition'];
    let filename = 'document.pdf';

    if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }

    const element = document.createElement('a');
    element.href = url;
    element.download = filename;

    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
    URL.revokeObjectURL(url);
}

export function convertDateFormat(dateStr: any) {
    // Check if dateStr is empty, null, or undefined
    if (!dateStr) {
        return ''; // or you can return null or any other default value
    }

    // Split the input date string by '/'
    const [day, month, year] = dateStr.split('/');

    // Convert day and month to integers to remove leading zeros
    const dayInt = parseInt(day, 10);
    const monthInt = parseInt(month, 10);
    // Return the date string in 'mm/dd/yyyy' format
    return `${monthInt}/${dayInt}/${year}`;
}
